import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "../../routes";
import Isotope from "isotope-layout";
import AOS from "aos";

const select = (el, all = false) => {
  el = el.trim();
  if (all) {
    return [...document.querySelectorAll(el)];
  } else {
    return document.querySelector(el);
  }
};

const on = (type, el, listener, all = false) => {
  let selectEl = select(el, all);
  if (selectEl) {
    if (all) {
      selectEl.forEach((e) => e.addEventListener(type, listener));
    } else {
      selectEl.addEventListener(type, listener);
    }
  }
};

const Gallery = () => {
  useEffect(() => {
    let galleryContainer = document.querySelector(".gallery-container");
    if (galleryContainer) {
      let galleryIsotope = new Isotope(galleryContainer, {
        itemSelector: ".gallery-item",
        layoutMode: "fitRows",
      });

      let galleryFilters = select("#gallery-flters li", true);

      on(
        "click",
        "#gallery-flters li",
        function (e) {
          e.preventDefault();
          galleryFilters.forEach((el) => {
            el.classList.remove("filter-active");
          });
          this.classList.add("filter-active");

          galleryIsotope.arrange({
            filter: this.getAttribute("data-filter"),
          });
          galleryIsotope.on("arrangeComplete", function () {
            AOS.refresh();
          });
        },
        true
      );
    }
  });

  return (
    <>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Gallery</h2>
            <ol>
              <li>
                <Link to={WebRoutes.HOME}>Home</Link>
              </li>
              <li>Gallery</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs Section --> */}

      {/* <!-- ======= Gallery Section ======= --> */}
      <section className="gallery">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Gallery</h2>
            <p>Check out our beautifull Gallery</p>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <ul id="gallery-flters">
                <li data-filter="*" className="filter-active">
                  All
                </li>
                <li data-filter=".Health">Health</li>
                <li data-filter=".Education">Education</li>
                <li data-filter=".Environment">Environment</li>
              </ul>
            </div>
          </div>

          <div className="row gallery-container" data-aos="fade-up" data-aos-delay="200">
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-2.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-2.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-6.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Distributes Masks & Sanitizers in Rural Areas">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-6.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-9.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-9.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-10.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-10.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-7.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Distributes Masks & Sanitizers in Rural Areas">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-7.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-8.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Distributes Masks & Sanitizers in Rural Areas">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-8.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-9.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Distributes Masks & Sanitizers in Rural Areas">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-9.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-10.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Distributes Masks & Sanitizers in Rural Areas">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-10.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-11.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Distributes Masks & Sanitizers in Rural Areas">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-11.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-12.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Distributes Masks & Sanitizers in Rural Areas">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-12.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/23.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Doctor examining patient's Report">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/23.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-15.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-15.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-6 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/2.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Inauguration Ceremony of Sharda Jeet Foundation">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/2.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-3.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-3.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-20.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-20.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-9.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-9.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/22.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Dr. Prakash Parimal & Our President Mr. Somesh Kumar">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/22.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-21.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-21.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-1.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-1.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-17.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-17.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-5.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-5.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-6.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-6.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-7.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-7.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-13.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-13.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-2.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Distributes Masks & Sanitizers in Rural Areas">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-2.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-3.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Distributes Masks & Sanitizers in Rural Areas">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-3.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/17.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Doctor examining patient's Report">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/17.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/13.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Dr. Prakash Parimal">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/13.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-11.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-11.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-12.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-12.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-1.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-1.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-2.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-2.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-3.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-3.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-4.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Distributes Masks & Sanitizers in Rural Areas">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-4.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-5.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Distributes Masks & Sanitizers in Rural Areas">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-5.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/14.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Dr. Prakash Parimal examining patient">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/14.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-4.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-4.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-5.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-5.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-8.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-8.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-18.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-18.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/1.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/1.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/9.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Doctor's Team examining patients and preparing reports">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/9.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/10.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Doctor examining patients">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/10.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/7.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Doctor examining patients">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/7.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-6 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/2.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Inauguration Ceremony of Sharda Jeet Foundation">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/2.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/22.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Dr. Prakash Parimal & Our President Mr. Somesh Kumar">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/22.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/23.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Doctor examining patient's Report">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/23.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/14.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Dr. Prakash Parimal examining patient">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/14.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-14.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-14.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-10.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-10.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-6.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-6.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-19.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-19.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-16.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-16.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Education">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-7.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/img-7.jpeg"} className="img-fluid" alt="Education" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/15.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Our Founder, President, Doctors & Volunteers">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/15.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/16.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Patients waiting for their turn to get treated">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/16.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-4.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-4.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-1.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Distributes Masks & Sanitizers in Rural Areas">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/img-1.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-8.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/img-8.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/21.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Sharda Jeet Foundation helps the needy patients to get their eye operation done">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/21.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/18.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Patients waiting for their turn to get treated">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/18.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/19.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Dr. Prakash Parimal's Team">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/19.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/3.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Doctor examining patients">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/3.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/4.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Doctor's Team examining patients and preparing reports">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/4.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/5.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Doctor examining patients">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/5.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/6.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Our Founder & Volunteers Helping Patients">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/6.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/8.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Doctor examining patients">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/8.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/11.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Doctor examining patients">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/11.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/12.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Patients waiting for their turn to get treated">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/12.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/20.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Dr. Prakash Parimal examining patient">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/20.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item Health">
              <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/24.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="Medicines distributed by Sharda Jeet Foundation">
                <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/24.jpeg"} className="img-fluid" alt="Health" />
              </a>
            </div>

            <div className="col-lg-3 col-md-6 gallery-item">
              <a href={WebRoutes.IMG_BASE_URL + "programs/ChhathPuja/img-1.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="शारदा जीत फाउंडेशन के द्वारा छठ पूजा के अवसर पर श्रद्धालुओं को पूजा सामग्री बांटते हुए कार्यकर्ता|">
                <img src={WebRoutes.IMG_BASE_URL + "programs/ChhathPuja/img-1.jpeg"} className="img-fluid" alt="छठ पूजा" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item">
              <a href={WebRoutes.IMG_BASE_URL + "programs/ChhathPuja/img-2.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="छठ पूजा के अवसर पर शारदा जीत फाउंडेशन ने श्रद्धालुओं को बांटी पूजा सामग्री|">
                <img src={WebRoutes.IMG_BASE_URL + "programs/ChhathPuja/img-2.jpeg"} className="img-fluid" alt="छठ पूजा" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item">
              <a href={WebRoutes.IMG_BASE_URL + "programs/ChhathPuja/img-3.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="शारदा जीत फाउंडेशन के द्वारा छठ पूजा के अवसर पर श्रद्धालुओं को पूजा सामग्री बांटते हुए कार्यकर्ता|">
                <img src={WebRoutes.IMG_BASE_URL + "programs/ChhathPuja/img-3.jpeg"} className="img-fluid" alt="छठ पूजा" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item">
              <a href={WebRoutes.IMG_BASE_URL + "programs/ChhathPuja/img-4.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="छठ पूजा के अवसर पर शारदा जीत फाउंडेशन ने श्रद्धालुओं को बांटी पूजा सामग्री|">
                <img src={WebRoutes.IMG_BASE_URL + "programs/ChhathPuja/img-4.jpeg"} className="img-fluid" alt="छठ पूजा" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item">
              <a href={WebRoutes.IMG_BASE_URL + "programs/ChhathPuja/img-5.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="शारदा जीत फाउंडेशन के द्वारा छठ पूजा के अवसर पर श्रद्धालुओं को पूजा सामग्री बांटते हुए कार्यकर्ता|">
                <img src={WebRoutes.IMG_BASE_URL + "programs/ChhathPuja/img-5.jpeg"} className="img-fluid" alt="छठ पूजा" />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 gallery-item">
              <a href={WebRoutes.IMG_BASE_URL + "programs/ChhathPuja/img-6.jpeg"} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap" data-caption="छठ पूजा के अवसर पर शारदा जीत फाउंडेशन ने श्रद्धालुओं को बांटी पूजा सामग्री|">
                <img src={WebRoutes.IMG_BASE_URL + "programs/ChhathPuja/img-6.jpeg"} className="img-fluid" alt="छठ पूजा" />
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Gallery Section --> */}
    </>
  );
};

export default Gallery;
