import { Route, Routes } from "react-router-dom";
import BaseComponent from "./components/baseComponent";
import Home from "./components/home/home";
import About from "./components/whoWeAre/about";
import Team from "./components/whoWeAre/team";
import VisionMission from "./components/whoWeAre/vision-mission";
import Education from "./components/programs/education";
import Environment from "./components/programs/environment";
import Health from "./components/programs/health";

import Gallery from "./components/gallery/gallery";
import MediaCoverage from "./components/mediaCoverage/mediaCoverage";
import Awareness from "./components/awareness/index";
import Contact from "./components/contact/contact";
import Donate from "./components/donate";
import PrivacyPolicy from "./components/policies/privacy-policy";
import RefundPolicy from "./components/policies/refund-policy";
import TermsConditions from "./components/policies/terms-conditions";
import { WebRoutes } from "./routes";
import ScrollToTop from "./components/common/ScrollToTop";
import BridgingEducationGap from "./components/awareness/blog/BridgingEducationGap";
import WaterHarvesting from "./components/awareness/blog/WaterHarvesting";
import DigitalEyeStrain from "./components/awareness/blog/EyeStrain";
import ResponsePage from "./components/Response/ResponsePage";
import SuccessPage from "./components/Response/SuccessPage"

function App() {
  return (
    <>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<BaseComponent />}>
            <Route index element={<Home />} />

            <Route path={WebRoutes.ABOUT_US} element={<About />} />
            <Route path={WebRoutes.VISION_MISSION} element={<VisionMission />} />
            <Route path={WebRoutes.OUR_TEAM} element={<Team />} />

            <Route path={WebRoutes.PROGRAM_HEALTH} element={<Health />} />
            <Route path={WebRoutes.PROGRAM_EDUCATION} element={<Education />} />
            <Route path={WebRoutes.PROGRAM_ENVIRONMENT} element={<Environment />} />

            <Route path={WebRoutes.GALLERY} element={<Gallery />} />
            <Route path={WebRoutes.MEDIA_COVERAGE} element={<MediaCoverage />} />
            <Route path={WebRoutes.CONTACT_US} element={<Contact />} />
            <Route path={WebRoutes.DONATE} element={<Donate />} />
            <Route path={WebRoutes.RESPONSEPAGE} element={<ResponsePage/>}/>
            <Route path={WebRoutes.SUCCESSPAGE} element={<SuccessPage/>}/>

            <Route path={WebRoutes.AWARENESS} element={<Awareness />} />
            <Route path={WebRoutes.BRIDGING_EDUCATION_GAPS} element={<BridgingEducationGap />} />
            <Route path={WebRoutes.WATER_HARVESTING} element={<WaterHarvesting />} />
            <Route path={WebRoutes.DIGITAL_EYE_STRAIN} element={<DigitalEyeStrain />} />

            <Route path={WebRoutes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path={WebRoutes.REFUND_POLICY} element={<RefundPolicy />} />
            <Route path={WebRoutes.TERMS_CONDITIONS} element={<TermsConditions />} />

           
          </Route>
        </Routes>
      </ScrollToTop>
    </>
  );
}

export default App;
