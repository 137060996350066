import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "./../../routes";

const MediaCoverage = () => {
  const mediaImg = [
    { size: 5, img: "CutOut-Jan-1.png" },
    { size: 2, img: "CutOut-Dec-3.png" },
    { size: 5, img: "CutOut-Dec-1.png" },
    { size: 4, img: "CutOut-Nov-1.png" },
    { size: 8, img: "CutOut-Nov-2.png" },
    { size: 5, img: "CutOut-Dec-2.png" },
    { size: 6, img: "CutOut-Nov-3.png" },
    { size: 6, img: "CutOut-Nov-4.png" },
    { size: 6, img: "CutOut-Nov-5.png" },
  ];
  return (
    <>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Media Coverage</h2>
            <ol>
              <li>
                <Link to={WebRoutes.HOME}>Home</Link>
              </li>
              <li>Media Coverage</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs Section --> */}

      {/* <!-- ======= Media Coverage ======= --> */}
      <section className="gallery">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Media Coverage</h2>
            <p>Press Releases</p>
          </div>

          <div className="row align-content-stretch flex-wrap gallery-container" data-aos="fade-up" data-aos-delay="200">
            {mediaImg.map((item, index) => {
              return (
                <div className={`col-lg-${item.size} col-md-6`} key={index}>
                  <a href={WebRoutes.IMG_BASE_URL + "mediaCoverage/" + item.img} data-fancybox="gallery">
                    <img src={WebRoutes.IMG_BASE_URL + "mediaCoverage/" + item.img} alt="Media-CutOut" />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* <!-- End Media Coverage Section --> */}
    </>
  );
};

export default MediaCoverage;
