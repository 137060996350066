import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "./../../routes";

const TermsConditions = () => {
  return (
    <>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Terms & Conditions</h2>
            <ol>
              <li>
                <Link to={WebRoutes.HOME}>Home</Link>
              </li>
              <li>Terms & Conditions</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs Section --> */}

      {/* <!-- ======= Terms & Conditions ======= --> */}
      <section>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-12 pt-5 pt-lg-0">
              <h3 data-aos="fade-up" className="mb-4 SJF">
                <b>Terms & Conditions</b>
              </h3>
              <img data-aos="fade-left" data-aos-delay="100" src={require("../../static/img/terms.png")} className="img-fluid float-end" alt="Policy" />
              <h5 data-aos="fade-up" data-aos-delay="100">
                <b>Use of this site is provided by Sharda Jeet Foundation subject to the following terms and condition:</b>
              </h5>
              <p data-aos="fade-up" data-aos-delay="100" className="text-justify">
                Your use constitutes acceptance of these terms and conditions as at the date of your first use of the site.
              </p>
              <p data-aos="fade-up" data-aos-delay="200" className="text-justify">
                Sharda Jeet Foundation reserves the rights to change these terms and conditions at any time by posting changes online. Your continued use of this site after changes are posted constitutes your acceptance of this agreement as modified.
              </p>
              <p data-aos="fade-up" data-aos-delay="300" className="text-justify">
                You agree to use this site only for lawful purposes, and in a manner which does not infringe the rights, or restrict, or inhibit the use and enjoyment of the site by any third party.
              </p>
              <p data-aos="fade-up" data-aos-delay="400" className="text-justify">
                This site and the information, names, images, pictures, logos regarding or relating to Sharda Jeet Foundation are provided “as is” without any representation or endorsement made and without warranty of any kind whether express or implied. In no event will Sharda Jeet Foundation be liable for any damages including, without limitation, indirect or consequential damages, or any damages whatsoever arising from the use or in connection with such use or loss of use of the site, whether in contract or in negligence.
              </p>

              <h5 data-aos="fade-up" data-aos-delay="500">
                <b>Copyright restrictions:</b>
              </h5>
              <p data-aos="fade-up" data-aos-delay="500" className="text-justify">
                Commercial use or publication of all or any item displayed is strictly prohibited without prior authorization from Sharda Jeet Foundation . Nothing contained herein shall be construed as conferring any license by Sharda Jeet Foundation to use any item displayed.
              </p>
              <p data-aos="fade-up" data-aos-delay="500" className="text-justify">
                Documents may be copied for personal use only on the condition that copyright and source indications are also copied, no modifications are made and the document is copied entirely. However, some documents and photos have been published on this site with the permission of the relevant copyright owners (Sharda Jeet Foundation). All rights are reserved on these documents and permission to copy them must be requested from the copyright owners (the sources are indicated within these documents/photographs).
              </p>
              <p data-aos="fade-up" data-aos-delay="500" className="text-justify">
                By posting or submitting content on our site, you grant Sharda Jeet Foundation a non-exclusive, royalty-free, perpetual, worldwide license to republish any material you submit to us, without limitation, in any format.
              </p>
              <p data-aos="fade-up" data-aos-delay="500" className="text-justify">
                Sharda Jeet Foundation takes no responsibility for the content of external Internet sites. Other websites that we link to are owned and operated by third parties and Sharda Jeet Foundation has no control over them. The fact that we include links to other websites does not mean that Sharda Jeet Foundation approves of or endorses any other third party website or the content of that website. We accept no liability for any statements, information, products or services that are published on or are accessible through any websites owned or operated by third parties.
              </p>
              <p data-aos="fade-up" data-aos-delay="500" className="text-justify">
                Any organization or company profiled on personal or team fundraising pages are in support of individual participants or teams participating in Sharda Jeet Foundation events. Sharda Jeet Foundation does not endorse any product or service by participant or team corporate associations unless specifically listed on the sponsorship page of our website.
              </p>
              <p data-aos="fade-up" data-aos-delay="600" className="text-justify">
                Any communication or material that you transmit to, or post on, any public area of the site including any data, questions, comments, suggestions, or the like, is, and will be treated as, non-confidential and non-proprietary information.
              </p>
              <p data-aos="fade-up" data-aos-delay="600" className="text-justify">
                Sharda Jeet Foundation accepts no liability in respect of any material publicly posted by users of this website, nor are we responsible for the content and accuracy of that material. Any views expressed in user-submitted content are not the views of Sharda Jeet Foundation .
              </p>
              <p data-aos="fade-up" data-aos-delay="600" className="text-justify">
                Sharda Jeet Foundation reserves the right to terminate a user’s website account if we believe you are abusing the service in any way, or have breached these terms and conditions.
              </p>
              <p data-aos="fade-up" data-aos-delay="600" className="text-justify">
                If there is any conflict between these terms and conditions and rules and/or specific terms of use appearing on this site relating to specific material then the latter shall prevail.
              </p>
              <p data-aos="fade-up" data-aos-delay="600" className="text-justify">
                If these terms and conditions are not accepted in full, the use of this site must be terminated immediately.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Refund Policy Section --> */}
    </>
  );
};

export default TermsConditions;
