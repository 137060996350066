import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "./../../routes";

const About = () => {
  return (
    <>
      {/* <!-- ======= About Section ======= --> */}
      <section id="about" className="about">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-12 pt-5 pt-lg-0">
              <h3 data-aos="fade-up" className="SJF">
                About Us
              </h3>
              <p data-aos="fade-up" data-aos-delay="100" className="text-justify">
                Sharda Jeet Foundation (SJF) got established in the year 2021. It is a Social Service Organization working for the welfare and empowerment of Underprivileged Community. The mission of this foundation is to provide with the necessary resources like books, pencil, notebooks, scholarships to the selected students, etc. to the students which are required for the completion of their studies and in the health segment we are mainly focused on the eyesight segment as one can do some amount of work even if he/ she is physically challenged, but being blind or being a person with week eyesight makes it quite a difficult task. So for that we provide people with weak eyesight the necessary equipment’s like specs, organizing timely eyesight checkup camps and also refer people to the
                appropriate doctors.
              </p>
              <p data-aos="fade-up" data-aos-delay="200" className="text-justify">
                SJF is a non-profitable organization registered under Section 8 of the Company’s Act 2013. We work in the areas like Jharkhand and also have it as our main center. We have a team of professionals and a management team to manage all the things on ground and on social media platforms.
              </p>
              <p data-aos="fade-up" data-aos-delay="300" className="text-justify">
                You can join with us or help us in our initiative towards the betterment of the underprivileged community by donating/ contributing whatever you like it can be monetarily or by providing us the necessary goods like books, notebooks, pen, specs, etc.
              </p>
              <div className="row">
                <div className="col-md-4" data-aos="fade-up" data-aos-delay="400" data-aos-duration="600">
                  <Link className="sector-hlght" to={WebRoutes.HEALTH}>
                    <span>
                      <img src="https://kyc.dialmytrip.com/ShardaJeetFoundation/img/programs/Health-1.png" className="img-fluid" alt="Health" />
                    </span>
                    <h4>Health</h4>
                    <p>We aspire to help the underprivileged people with less, inappropriate or no vision by organizing time to time camps and providing basic medical facilities.</p>
                  </Link>
                </div>

                <div className="col-md-4" data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">
                  <Link className="sector-hlght" to={WebRoutes.PROGRAM_EDUCATION}>
                    <span>
                      <img src="https://kyc.dialmytrip.com/ShardaJeetFoundation/img/programs/Education-1.png" className="img-fluid" alt="Education" />
                    </span>
                    <h4>Education</h4>
                    <p>In this program our main motive is to complete basic educational requirements of the underprivileged students/ children for their better education and brighter future. </p>
                  </Link>
                </div>

                <div className="col-md-4" data-aos="fade-up" data-aos-delay="600" data-aos-duration="600">
                  <Link className="sector-hlght" to={WebRoutes.PROGRAM_ENVIRONMENT}>
                    <span>
                      <img src="https://kyc.dialmytrip.com/ShardaJeetFoundation/img/programs/Environment-1.png" className="img-fluid" alt="Environment" />
                    </span>
                    <h4>Environment</h4>
                    <p>We are focused on the most important part of the environment i.e. the plants and hence spread awareness and provide samplings for the tree plantation.</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Section --> */}
    </>
  );
};

export default About;
