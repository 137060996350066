import React from "react";
import { Link } from "react-router-dom";
import "../../static/css/awareness.css";
import { WebRoutes } from "./../../routes";

const Awareness = () => {
  return (
    <>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Awareness</h2>
            <ol>
              <li>
                <Link to={WebRoutes.HOME}>Home</Link>
              </li>
              <li>Awareness</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs Section --> */}

      {/* <!-- ======= Awareness Section ======= --> */}
      <section className="awareness">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-md-4">
              <Link className="awarenessBlog" to={WebRoutes.WATER_HARVESTING}>
                <img src={WebRoutes.IMG_BASE_URL + "blog/WaterHarvesting.png"} className="img-fluid" alt="WaterHarvesting" />
                <ul>
                  <li>
                    <i className="icofont-globe"></i>
                    <span>Sharda Jeet Foundation</span>
                  </li>
                  <li>
                    <i className="icofont-ui-calendar"></i>
                    <span>October, 17 2021</span>
                  </li>
                </ul>
                <h4>How to Save Litres of Water Every day to combat water scarcity.</h4>
                <p>To save litres of water every day you can use some basic tricks and tips while practicing household work. One of the ways by which water can be saved is by reus</p>
                <span className="readMore">
                  Read More <i className="icofont-rounded-right"></i>
                </span>
              </Link>
            </div>

            <div className="col-md-4">
              <Link className="awarenessBlog" to={WebRoutes.BRIDGING_EDUCATION_GAPS}>
                <img src={WebRoutes.IMG_BASE_URL + "blog/BridgingEduGap.png"} className="img-fluid" alt="BridgingEduGap" />
                <ul>
                  <li>
                    <i className="icofont-globe"></i>
                    <span>Sharda Jeet Foundation</span>
                  </li>
                  <li>
                    <i className="icofont-ui-calendar"></i>
                    <span>September, 24 2021</span>
                  </li>
                </ul>
                <h4>Helping children who lost their parents in Pandemic</h4>
                <p>We all lost our near and dear ones due to the outrage of this pandemic and so are there many children who lost their parents in it. And now they have no one to</p>
                <span className="readMore">
                  Read More <i className="icofont-rounded-right"></i>
                </span>
              </Link>
            </div>

            <div className="col-md-4">
              <Link className="awarenessBlog" to={WebRoutes.DIGITAL_EYE_STRAIN}>
                <img src={WebRoutes.IMG_BASE_URL + "blog/EyeStrain.png"} className="img-fluid" alt="EyeStrain" />
                <ul>
                  <li>
                    <i className="icofont-globe"></i>
                    <span>Sharda Jeet Foundation</span>
                  </li>
                  <li>
                    <i className="icofont-ui-calendar"></i>
                    <span>August, 08 2021</span>
                  </li>
                </ul>
                <h4>All about Digital Eye Strain & How to Prevent it!!</h4>
                <p>It is an eye and vision problem caused due to prolonged use of digital devices. A person affected due to Digital Eye Strain experiences discomfort in eyes like</p>
                <span className="readMore">
                  Read More <i className="icofont-rounded-right"></i>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- End Awareness Section --> */}
    </>
  );
};

export default Awareness;
