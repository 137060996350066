import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "../../routes";

const VisionMission = () => {
  return (
    <>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Vision & Mission</h2>
            <ol>
              <li>
                <Link to={WebRoutes.HOME}>Home</Link>
              </li>
              <li>Vision & Mission</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs Section --> */}

      {/* <!-- ======= Vision & Mission Section ======= --> */}
      <section>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-8 pt-5 pt-lg-0">
              <h5 data-aos="fade-up" data-aos-delay="100">
                <b>Educational</b>
              </h5>
              <p data-aos="fade-up" data-aos-delay="200" className="text-justify">
                Sharda Jeet Foundation aims to provide quality education to the underprivileged by completing all their basic educational requirements. By providing them all the necessities like books, notebooks, pens, pencils, school bag, etc.
              </p>

              <h5 data-aos="fade-up" data-aos-delay="300">
                <b>Health</b>
              </h5>
              <p data-aos="fade-up" data-aos-delay="400" className="text-justify">
                In health we are mainly focused on the problems related to eyesight. As even a physically challenged person can work in some way or the other but it’s quite a difficult task for a person with no vision or un-clear vision to do so. SJF is in a mission to provide such people the required eye drops, medicines, specs, free eye sight camps or by recommending them to the appropriate doctors.
              </p>
            </div>
            <div className="col-lg-4 pt-5 pt-lg-0">
              <img src={require("../../static/img/Vision.png")} className="img-fluid" data-aos="fade-left" data-aos-delay="100" alt="Vision" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Vision & Mission Section --> */}
    </>
  );
};

export default VisionMission;
