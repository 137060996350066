import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "../../../routes";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const activitiesCarousel = {
  stagePadding: 0,
  loop: false,
  nav: false,
  dots: false,
  margin: 20,
  autoplay: true,
  autoplayTimeout: 1000,
  autoplayHoverPause: true,
  autoplaySpeed: 500,
  rewind: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};

const _12Jan2022 = ["img-1.jpeg", "img-2.jpeg", "img-3.jpeg", "img-4.jpeg", "img-5.jpeg", "img-6.jpeg", "img-7.jpeg", "img-8.jpeg", "img-9.jpeg", "img-10.jpeg", "img-11.jpeg", "img-12.jpeg", "img-13.jpeg", "img-14.jpeg", "img-15.jpeg", "img-16.jpeg", "img-17.jpeg", "img-18.jpeg", "img-19.jpeg", "img-20.jpeg", "img-21.jpeg"];

const Education = () => {
  return (
    <>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Programs</h2>
            <ol>
              <li>
                <Link to={WebRoutes.HOME}>Home</Link>
              </li>
              <li>Programs - Education</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs Section --> */}

      {/* <!-- ======= Education Program ======= --> */}
      <section id="Environment" className="Environment">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-12 pt-5 pt-lg-0">
              <h3 data-aos="fade-up" className="mb-4 SJF">
                <b>Education Program</b>
              </h3>
              <img data-aos="fade-left" data-aos-delay="100" src="https://kyc.dialmytrip.com/ShardaJeetFoundation/img/programs/education.png" className="img-fluid float-end" alt="program" />
              <p data-aos="fade-up" data-aos-delay="100" className="text-justify">
                In health Sharda Jeet Foundation is mainly focused on the Eyesight of people as it is the most important organ of our body and it is quite a difficult task to do work with no vision or less vision.
              </p>
              <p data-aos="fade-up" data-aos-delay="200" className="text-justify">
                Generally the women of the underprivileged community earn by stitching and sewing and if they will have less vision and no proper medical equipments then it would become really difficult for them to work like that. Sharda Jeet helps people by giving Eye medical treatment like Spectacles i.e.
              </p>
              <p data-aos="fade-up" data-aos-delay="300" className="text-justify">
                SJF is mainly focused on such problems. We operate timely Eyesight Camps to check what exactly the problem of a person is so that he/ she can later get it treated accordingly. We also provide them with the basic medical facilities such as medicines, eye drops, specks and referring them to the appropriate doctor according to their needs.
              </p>
              <p data-aos="fade-up" data-aos-delay="400" className="text-justify">
                There is a reason behind choosing eyesight under health i.e. to make people fit for doing a job or some work which will increase their earnings and obviously they will lead a better life with clear vision.
              </p>
              <p data-aos="fade-up" data-aos-delay="500" className="text-justify">
                Help us make it possible by donating or contributing your bit to us.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Education Section --> */}

      {/* <!-- ======= Education Program ======= --> */}
      <section id="Activities" className="Activities pt-4">
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Our Activities</h2>
            <p>Our Contribution towards Education</p>
          </div>

          <div className="row">
            <div className="col-md-12" data-aos="fade-up">
              <div className="activityDescribe">
                <h3>Distribution of Educational Material to Underprivileged Children</h3>
                <span className="dte">12, January 2022</span>
              </div>
              <p className="captn">Sharda Jeet Foundation organized the Mask & Sanitizer distribution & also provides educational material consisting of notebooks & pencils. Our mission is to provide with the necessary resources like books, pencil, notebooks, and scholarships to the selected students for the completion of their higher studies.</p>
              {_12Jan2022 && (
                <OwlCarousel className="activities-carousel owl-theme" {...activitiesCarousel}>
                  {_12Jan2022.map((item, index) => {
                    return (
                      <div className="item" key={index}>
                        <a href={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/" + item} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                          <img src={WebRoutes.IMG_BASE_URL + "programs/Education/12Jan2022/" + item} className="img-fluid rounded" alt="SJF-Activities" />
                        </a>
                      </div>
                    );
                  })}
                </OwlCarousel>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Education Section --> */}
    </>
  );
};

export default Education;
