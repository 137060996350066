import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "./../../../routes";

const BridgingEducationGap = () => {
  return (
    <>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Awareness</h2>
            <ol>
              <li>
                <Link to={WebRoutes.HOME}>Home</Link>
              </li>
              <li>
                <Link to={WebRoutes.AWARENESS}>Awareness</Link>
              </li>
              <li>Bridging Education Gaps</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs Section --> */}

      {/* <!-- ======= Awareness Banner Section ======= --> */}
      <div className="awarenessBanner" style={{ backgroundImage: `url(${WebRoutes.IMG_BASE_URL + "blog/BridgingEduGap.png"})` }}>
        <div className="row">
          <div className="col-12">
            <h2>Bridging Education Gaps</h2>
          </div>
        </div>
      </div>
      {/* <!-- End Awareness Banner Section --> */}

      <section>
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-8">
              <p>We all lost our near and dear ones due to the outrage of this pandemic and so are there many children who lost their parents in it. And now they have no one to help and educate them, there is government who sponsors the funds for their studies but that is not enough as they require other study material too for fulfilling their basic educational requirements.</p>
              <p>Sharda Jeet Foundation (SJF) understands their needs and hence takes the authority of helping them out. We are currently providing basic educational necessities to many of the children.</p>
              <p>We ask you to donate a bit amount or some study material for the same.</p>
            </div>
            <div className="col-4">
              <img src={WebRoutes.IMG_BASE_URL + "blog/BridgingEduGap.png"} className="img-fluid img-thumbnail" alt="BridgingEduGap" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BridgingEducationGap;
