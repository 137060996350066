import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { WebRoutes } from "../../routes";

const Header = () => {
 const[data,setData]=useState(false);
const[value,setValue]=useState();
 const handleChange=()=>{
  setData('bi-x bi-list');
  setValue('block');
 }
 const CrossHandle=()=>{
  setData(false);
  setValue('none');
  setShowUlFirst('none');
  setUlShowSecond('none');
 }
 const[showUlFirst,setShowUlFirst]=useState('none');
 const ShowUlFirst=()=>{
    setShowUlFirst('block');
    setUlShowSecond('none');
 }
 const[showUlSecond,setUlShowSecond]=useState('none');
 const ShowUlSecond=()=>{
  setUlShowSecond('block');
  setShowUlFirst('none');
 }
  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <Link to={WebRoutes.HOME}>
              <img src={require("../../static/img/logo.png")} alt="logo" className="img-fluid" />
            </Link>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <NavLink to={WebRoutes.HOME} className="nav-link">
                  Home
                </NavLink>
              </li>
              <li className="dropdown">
                <Link to={WebRoutes.ABOUT_US}>
                  <span>Who we are?</span> <i className="icofont-rounded-down"></i>
                </Link>
                <ul>
                  <li>
                    <Link to={WebRoutes.ABOUT_US}>About Us</Link>
                  </li>
                  <li>
                    <Link to={WebRoutes.VISION_MISSION}>Vision & Mission</Link>
                  </li>
                  <li>
                    <Link to={WebRoutes.OUR_TEAM}>Our Team</Link>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="https://www.shardajeetfoundation.org" onClick={(e) => e.preventDefault()}>
                  <span>Programs</span> <i className="icofont-rounded-down"></i>
                </a>
                <ul>
                  <li>
                    <Link to={WebRoutes.PROGRAM_HEALTH}>Health</Link>
                  </li>
                  <li>
                    <Link to={WebRoutes.PROGRAM_EDUCATION}>Education</Link>
                  </li>
                  <li>
                    <Link to={WebRoutes.PROGRAM_ENVIRONMENT}>Environment</Link>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to={WebRoutes.GALLERY} className="nav-link">
                  Gallery
                </NavLink>
              </li>
              <li>
                <NavLink to={WebRoutes.MEDIA_COVERAGE} className="nav-link">
                  Media Coverage
                </NavLink>
              </li>
              <li>
                <NavLink to={WebRoutes.AWARENESS} className="nav-link">
                  Awareness
                </NavLink>
              </li>
              <li>
                <NavLink to={WebRoutes.CONTACT_US} className="nav-link">
                  Contact Us
                </NavLink>
              </li>
              <li>
                <NavLink to={WebRoutes.DONATE} className="getstarted">
                  Donate <i className="icofont-heart-alt"></i>
                </NavLink>
              </li>
            </ul>
              {!data?<i className={`bi bi-list mobile-nav-toggle `} id="menubar" onClick={handleChange} ></i>:
              <i className={`bi mobile-nav-toggle ${data}`} id="menubar" onClick={CrossHandle} ></i>}
          </nav>
          {/* mobile navigation */}
          <div className="mobile" style={{display:value}}>
          <ul>
          <li>
            <NavLink to={WebRoutes.HOME} className="nav-link" onClick={CrossHandle}>
              Home
            </NavLink>
          </li>
          <li className="dropdown who">
            <Link to={WebRoutes.ABOUT_US} onClick={ShowUlFirst}>
              <span>Who we are?</span> <i className="icofont-rounded-down"></i>
            </Link>
            <ul style={{display:showUlFirst}}>
              <li>
                <Link to={WebRoutes.ABOUT_US}  onClick={CrossHandle}>About Us</Link>
              </li>
              <li>
                <Link to={WebRoutes.VISION_MISSION}  onClick={CrossHandle}>Vision & Mission</Link>
              </li>
              <li>
                <Link to={WebRoutes.OUR_TEAM}  onClick={CrossHandle}>Our Team</Link>
              </li>
            </ul>
          </li>
          <li className="dropdown" onClick={ShowUlSecond}>
            <a href="https://www.shardajeetfoundation.org" onClick={(e) => e.preventDefault()}>
              <span>Programs</span> <i className="icofont-rounded-down"></i>
            </a>
            <ul style={{display:showUlSecond}}>
              <li>
                <Link to={WebRoutes.PROGRAM_HEALTH}  onClick={CrossHandle}>Health</Link>
              </li>
              <li>
                <Link to={WebRoutes.PROGRAM_EDUCATION}  onClick={CrossHandle}>Education</Link>
              </li>
              <li>
                <Link to={WebRoutes.PROGRAM_ENVIRONMENT}  onClick={CrossHandle}>Environment</Link>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to={WebRoutes.GALLERY} className="nav-link"  onClick={CrossHandle}>
              Gallery
            </NavLink>
          </li>
          <li>
            <NavLink to={WebRoutes.MEDIA_COVERAGE} className="nav-link"  onClick={CrossHandle}>
              Media Coverage
            </NavLink>
          </li>
          <li>
            <NavLink to={WebRoutes.AWARENESS} className="nav-link"  onClick={CrossHandle}>
              Awareness
            </NavLink>
          </li>
          <li>
            <NavLink to={WebRoutes.CONTACT_US} className="nav-link"  onClick={CrossHandle}>
              Contact Us
            </NavLink>
          </li>
          <li>
            <NavLink to={WebRoutes.DONATE} className="getstarted"  onClick={CrossHandle}>
              Donate <i className="icofont-heart-alt"></i>
            </NavLink>
          </li>
        </ul>
                
              
             
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
