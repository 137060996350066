import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "../../routes";

const Team = () => {
  return (
    <>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Our Team</h2>
            <ol>
              <li>
                <Link to={WebRoutes.HOME}>Home</Link>
              </li>
              <li>Our Team</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs Section --> */}

      {/* <!-- ======= Team ======= --> */}
      <section className="Team contact pt-4">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-12 text-center pt-4 mb-5">
              <span className="level">OUR INSPIRATION</span>
            </div>
            <div className="col-md-4 offset-md-4">
              <div className="member" data-aos="fade-up" data-aos-delay="100">
                <div className="picWrp">
                  <img src={WebRoutes.IMG_BASE_URL + "team/inspiration.png"} className="img-fluid rounded" alt="SJF Member" />
                </div>
                <h5>Sharda Kunwar</h5>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center pt-4 mb-5">
              <span className="level">FOUNDING MEMBERS</span>
            </div>
            <div className="col-md-3 offset-md-3">
              <div className="member" data-aos="fade-up" data-aos-delay="100">
                <div className="picWrp">
                  <img src={WebRoutes.IMG_BASE_URL + "team/Prem.png"} className="img-fluid" alt="SJF Member" />
                </div>
                <h5>Prem Prakash Singh</h5>
                <span>Founder & Chairman</span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="member" data-aos="fade-up" data-aos-delay="200">
                <div className="picWrp">
                  <img src={WebRoutes.IMG_BASE_URL + "team/Priti.png"} className="img-fluid" alt="SJF Member" />
                </div>
                <h5>Priti Kumari</h5>
                <span>Founder & Vice-Chairman</span>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 text-center pt-4 mb-5">
              <span className="level">Young and Dynamic Team behind Sharda Jeet Foundation</span>
            </div>
            <div className="col">
              <div className="member" data-aos="fade-up" data-aos-delay="100">
                <div className="picWrp">
                  <img src={WebRoutes.IMG_BASE_URL + "team/Somesh.png"} className="img-fluid" alt="SJF Member" />
                </div>
                <h5>Somesh Kumar</h5>
                <span>President</span>
              </div>
            </div>
            <div className="col">
              <div className="member" data-aos="fade-up" data-aos-delay="200">
                <div className="picWrp">
                  <img src={WebRoutes.IMG_BASE_URL + "team/Syed.png"} className="img-fluid" alt="SJF Member" />
                </div>
                <h5>Syed Aslam</h5>
                <span>Secretary</span>
              </div>
            </div>
            <div className="col">
              <div className="member" data-aos="fade-up" data-aos-delay="300">
                <div className="picWrp">
                  <img src={WebRoutes.IMG_BASE_URL + "team/Deepak.png"} className="img-fluid" alt="SJF Member" />
                </div>
                <h5>Deepak Kumar</h5>
                <span>Treasurer</span>
              </div>
            </div>
            <div className="col">
              <div className="member" data-aos="fade-up" data-aos-delay="400">
                <div className="picWrp">
                  <img src={WebRoutes.IMG_BASE_URL + "team/Santosh.png"} className="img-fluid" alt="SJF Member" />
                </div>
                <h5>Santosh Singh</h5>
                <span>Project Manager</span>
              </div>
            </div>
            <div className="col">
              <div className="member" data-aos="fade-up" data-aos-delay="500">
                <div className="picWrp">
                  <img src={WebRoutes.IMG_BASE_URL + "team/Ajeet.png"} className="img-fluid" alt="SJF Member" />
                </div>
                <h5>Ajeet Kashyap</h5>
                <span>IT Head</span>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
              <div className="info">
                <h5>Registered Address</h5>
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>
                    516, Vishal Chamber, Sector-18,
                    <br />
                    Noida-201301, Uttar Pradesh
                  </p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <a href="mailto:info@shardajeetfoundation.org">info@shardajeetfoundation.org</a>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <a className="mb-0" href="tel:7011796616">
                    +91 7011796616
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
              <div className="info">
                <h5>Branch Address</h5>
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>
                    Village Post: Dalakala,
                    <br />
                    District: Palamu, Jharkhand-822124
                  </p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <a href="mailto:info@shardajeetfoundation.org">info@shardajeetfoundation.org</a>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <a className="mb-0" href="tel:8877617261">
                    +91 8877617261
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Team Section --> */}
    </>
  );
};

export default Team;
