
import Pass from "./pass.gif";

const searchParams = new URLSearchParams(window.location.search);
  const mihpayid = searchParams.get('mihpayid');
  const firstname = searchParams.get('firstname');
  const email = searchParams.get('email');
  const amount = searchParams.get('amount');
const ResponsePage = () => {

     return (
      <div className="text-center" style={{height:"auto",backgroundColor:"#fff",display:"grid",placeItems:"center"}}>
      <h1>Payment Successfull</h1>
      <img src={Pass} alt="" height={200}/>
      <table class="table table-dark table-striped table-bordered">
      <thead>
        <tr>
          <th scope="col">Mihpayid</th>
          <th scope="col">Firstname</th>
          <th scope="col">Email</th>
          <th scope="col">Amout</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{mihpayid}</td>
          <td>{firstname}</td>
          <td>{email}</td>
          <td>{amount}</td>
        </tr>
      </tbody>
    </table>
      <p>Thank you for donation me!.....</p>
      </div>
    );
  }
  
 


export default ResponsePage