

import Failed from "./faild.gif";

const searchParams = new URLSearchParams(window.location.search);
  const mihpayid = searchParams.get('mihpayid');
  const firstname = searchParams.get('firstname');
  const email = searchParams.get('email');
  const amount = searchParams.get('amount');

const ResponsePage = () => {
 // const { mihpayid, amount, email } = useParams();
  
     return (
      <div className="text-center" style={{height:"auto",backgroundColor:"#fff",display:"grid",placeItems:"center"}}>
      <h1>Payment Cancelled</h1>
      <img src={Failed} alt="" height={100}/>
      <table class="table mt-2 table-striped table-bordered">
      <thead className="bg-dark text-white">
        <tr >
          <th scope="col">Mihpayid</th>
          <th scope="col">Firstname</th>
          <th scope="col">Email</th>
          <th scope="col">Amout</th>
        </tr>
      </thead>
      <tbody className="bg-info">
        <tr>
          <td>{mihpayid}</td>
          <td>{firstname}</td>
          <td>{email}</td>
          <td>{amount}</td>
        </tr>
      </tbody>
    </table>
      <p>please try again.....</p>
      </div>
    );
  }
  
 


export default ResponsePage