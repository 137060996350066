import React, {useState, useEffect} from "react";
// import PayU from "payu-sdk";
import {Link} from "react-router-dom";
import {WebRoutes} from "./../../routes";


const Donate = () => {
    const [key, setkey] = useState("nef5ku");
    const [number, setNumber] = useState();
    const [shoping, setShoping] = useState("");
    const [amount, setAmount] = useState();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [hash, setHash] = useState('');
    const[pan,setPan]=useState('');
    const[mobile,setMobile]=useState('');
    // 4R38IvwiV57FwVpsgOvTXBdLE4tHUXFW
    useEffect(() => {
        const randomNum = Math.floor(Math.random() * 10000000);
        setNumber(randomNum);
    }, [])
    console.log(number);
    async function generateHash() {

        const encoder = new TextEncoder();
        const data = encoder.encode(`${key}|${number}|${amount}|${shoping}|${name}|${email}|||||||||||nfs5rWjZdrCk9wc4YYBuMdx9reuLFtPO`);
        const hashBuffer = await crypto.subtle.digest('SHA-512', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        setHash(hashHex);
       //s console.log(hash);
    }

    useEffect(() => {
        generateHash();

    }, [amount]);

    const submitHandler = () => {
        console.log(hash);
    }
   
    const [redirectUrl, setRedirectUrl] = useState("http://localhost:5000/faild");
  // const history = useNavigate();

  // useEffect(()=>{
  //   history.push(redirectUrl);
  // },[]);
// const form = document.getElementById("myForm");
//   form.addEventListener("submit", (event) => {
//     event.preventDefault(); // Prevent the default form behavior
//     const formData = new FormData(form); // Get the form data
//     const name = formData.get("name"); // Get the value of the "name" input
//     // Do something with the form data, such as send it to an API or display it on the page
//     console.log("Name: ", name);
//   });
    return (
        <> {/* <!-- ======= Breadcrumbs Section ======= --> */}
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Donate</h2>
                        <ol>
                            <li>
                                <Link to={
                                    WebRoutes.HOME
                                }>Home</Link>
                            </li>
                            <li>Donate</li>
                        </ol>
                    </div>
                </div>
            </section>
            {/* <!-- End Breadcrumbs Section --> */}

            {/* <!-- ======= Donate Section ======= --> */}
            <section id="Donate" className="contact pb-4">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-5 pt-5 pt-lg-0">
                            <h3 data-aos="fade-up" className="mb-4 SJF">
                                <b>Donate - Support us helping others</b>
                            </h3>
                            <h5 data-aos="fade-up" data-aos-delay="100">
                                <b>Education</b>
                            </h5>
                            <p data-aos="fade-up" data-aos-delay="100" className="text-justify">
                                Education - Sharda Jeet Foundation (SJF) got established in the year 2021. It is a Social Service Organization working for the welfare and empowerment of Underprivileged Community. The mission of this foundation is to provide with the necessary resources like books, pencil, notebooks, and scholarships to the selected students.
                            </p>
                            <h5 data-aos="fade-up" data-aos-delay="200">
                                <b>Health</b>
                            </h5>
                            <p data-aos="fade-up" data-aos-delay="200" className="text-justify">
                                Health - Sharda Jeet Foundation (SJF) mainly focused on the eyesight segment as one can do some amount of work even if he/ she is physically challenged, but being blind or being a person with week eyesight makes it quite a difficult task. So for that we provide people with weak eyesight the necessary equipment’s like specs, organizing timely eyesight checkup camps and also refer people to the appropriate doctors.
                            </p>
                            <h5 data-aos="fade-up" data-aos-delay="300">
                                <b>Environment</b>
                            </h5>
                            <p data-aos="fade-up" data-aos-delay="300" className="text-justify">
                                Environment – We are focused on the most important part of the environment i.e the plants and hence spread awareness and provide samplings for the tree plantation.{" "} </p>
                        </div>

                        <div className="col-lg-7 pt-5 pt-lg-0">
                            <div className="contactUs-form" data-aos="fade-up" data-aos-delay="100">
                                <form action='https://secure.payu.in/_payment' method='post' id="myForm">
                                    <div className="col-12">
                                        <span className="heading">Payment Details</span>
                                    </div>
                                    <input type="hidden" name="key"
                                        value={key}
                                        onChange={
                                            (e) => setkey(e.target.value)
                                        }/>
                                    <input type="hidden" name="txnid"
                                        value={number}/>
                                    <div className="row">    
                                    <div className="form-group col-md-6">
                                    <label>Your Full Name</label>
                                    <input type="text" className="form-control" placeholder="Full Name"
                                        maxLength={30}
                                        required
                                        name="firstname"
                                        value={name}
                                        onChange={
                                            (e) => setName(e.target.value)
                                        }/> 
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            PAN
                                            <small className="text-muted">(Optional)</small>
                                        </label>
                                        <input type="text" className="form-control" placeholder="PAN" maxLength="10" value={pan} onChange={(e)=>setPan(e.target.value)} name="pan"/>
                                    </div>
                                    </div> 
                                    <div className="row">       
                                    <div className="form-group col-md-6">
                                   
                                        <label>Your Email ID</label>
                                        <input type="email" className="form-control" placeholder="Email ID" maxLength="40" required name="email"
                                            value={email}
                                            onChange={
                                                (e) => setEmail(e.target.value)
                                            }/>
                                    </div>
                                    <div className="form-group col-md-6">
                                    <label>Your Mobile Number</label>
                                    <input type="text" className="form-control" placeholder="Mobile Number" maxLength="10" required value={mobile} onChange={(e)=>setMobile(e.target.value)} name="number"  />
                                  </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-md-6">
                                    <label>Cause of your Choice</label>
                                    <input type="text" className="form-control" placeholder="Cause of your Choice" maxLength="30" name="productinfo"
                                        value={shoping}
                                        onChange={
                                            (e) => setShoping(e.target.value)
                                        }/> 
                                    </div>

                                    <div className="col-md-6">
                                       
                                            <label>Amount</label>
                                            <input type="text" className="form-control" placeholder="Amount" maxLength="6" required name="amount"
                                                value={amount}
                                                onChange={
                                                    (e) => setAmount(e.target.value)
                                                }/>
                                    </div>
                                    </div>
                                    <input type="hidden" name="surl" value="http://localhost:5000/pass"  />
                                    <input type="hidden" name="furl" value="http://localhost:5000/faild" />

                                    <input type="hidden" name="hash"
                                        value={hash}/>
                                    <div className="text-center mt-4">
                                        <button className="donate-btn"
                                            onClick={submitHandler}>Donate Now</button>
                                    </div>

                                </form>
                                <img src={
                                        require("../../static/img/secure_payment.png")
                                    }
                                    alt="Secure Payment"
                                    className="img-fluid"
                                    style={
                                        {
                                            width: "220px",
                                            marginTop: "50px"
                                        }
                                    }/>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
            {/* <!-- End Donate Section --> */}

            {/* <!-- ======= 80G Section ======= --> */}
            <section id="donation80G" className="donation80G-Wrp border-bottom-0 bg-white">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="donation80G" data-aos="flip-up" data-aos-delay="100">
                                <img className="img-fluid" src="https://kyc.dialmytrip.com/ShardaJeetFoundation/img/programs/Donation.png" alt="donation80G"/>
                                <span>All our efforts are made possible only because of your support</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="donation80G" data-aos="flip-up" data-aos-delay="250">
                                <img className="img-fluid" src="https://kyc.dialmytrip.com/ShardaJeetFoundation/img/programs/80G.png" alt="donation80G"/>
                                <span>Your donations are tax exempted under 80G of the Indian Income Tax Act</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="donation80G" data-aos="flip-up" data-aos-delay="400">
                                <img className="img-fluid" src="https://kyc.dialmytrip.com/ShardaJeetFoundation/img/programs/Secure.png" alt="donation80G"/>
                                <span>Your donation transactions are completely safe and secure</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End 80G Section --> */} </>
    );
};

export default Donate;
