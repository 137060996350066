import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "./../../../routes";

const WaterHarvesting = () => {
  return (
    <>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Awareness</h2>
            <ol>
              <li>
                <Link to={WebRoutes.HOME}>Home</Link>
              </li>
              <li>
                <Link to={WebRoutes.AWARENESS}>Awareness</Link>
              </li>
              <li>Water Harvesting</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs Section --> */}

      {/* <!-- ======= Awareness Banner Section ======= --> */}
      <div className="awarenessBanner" style={{ backgroundImage: `url(${WebRoutes.IMG_BASE_URL + "blog/WaterHarvesting.png"})` }}>
        <div className="row">
          <div className="col-12">
            <h2>Water Harvesting</h2>
          </div>
        </div>
      </div>
      {/* <!-- End Awareness Banner Section --> */}

      <section>
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-8">
              <p>To save litres of water every day you can use some basic tricks and tips while practicing household work. One of the ways by which water can be saved is by reusing the wastewater of the RO Water Purifying Systems.</p>
              <p>In India, almost all the households have a water purifying system which along with purifying the water leads to thrice the wastage of it as while the water gets purified just 25% of it gets cleansed and 75% of the water is wasted. This makes it a matter of great concern as we all know that the availability of drinking water is quite scarce in the world.</p>
              <p>There are many ways in which that water can be re-used, it includes watering plants, washing clothes, flushing toilets, washing cars, mopping the floors, etc. By doing so each household can save litres of water every day.</p>
              <p>So, today let us all take a pledge to put at least a bit of our contribution in water conservation. If we all will practice this process of conserving water, then by the end of the day we can save a thousand litres of water every day.</p>
            </div>
            <div className="col-4">
              <img src={WebRoutes.IMG_BASE_URL + "blog/WaterHarvesting.png"} className="img-fluid img-thumbnail" alt="WaterHarvesting" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WaterHarvesting;
