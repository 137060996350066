export const WebRoutes = {
  HOME: "/",
  ABOUT_US: "/about-us",
  VISION_MISSION: "/vision-mission",
  OUR_TEAM: "/our-team",
  PROGRAM_HEALTH: "/health",
  PROGRAM_EDUCATION: "/education",
  PROGRAM_ENVIRONMENT: "/environment",
  GALLERY: "/gallery",
  MEDIA_COVERAGE: "/media-coverage",
  AWARENESS: "/awareness",
  CONTACT_US: "/contact-us",
  DONATE: "/donate",
  PRIVACY_POLICY: "/privacy-policy",
  REFUND_POLICY: "/refund-policy",
  TERMS_CONDITIONS: "/terms-conditions",
  RESPONSEPAGE: "/responsepage",
  SUCCESSPAGE: "/successpage",
  WATER_HARVESTING: "/water-harvesting",
  BRIDGING_EDUCATION_GAPS: `/bridging-education-gaps`,
  DIGITAL_EYE_STRAIN: "/digital-eye-strain",

  IMG_BASE_URL: "https://kyc.dialmytrip.com/ShardaJeetFoundation/img/",
};
