import React from "react";
import { WebRoutes } from "./../../routes";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const contributionCarousel = {
  stagePadding: 0,
  loop: true,
  margin: 20,
  autoplay: true,
  dots: true,
  responsive: {
    0: {
      items: 1,
      nav: false,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const contributionCarouselData = [
  {
    title: "Monetarily",
    description: "You can donate whatever small or big amount you like in the form of net banking, UPI, credit and debit card, etc.",
  },
  {
    title: "Donations",
    description: "Donate in the form of study material, medicines, specs, eye drops, and plant samplings.",
  },
  {
    title: "Volunteer",
    description: "Individuals can volunteer and become a part of our organization to work towards the same purpose as us.",
  },
];

const storyCarousel = {
  items: 1,
  margin: 30,
  stagePadding: 150,
  autoplay: true,
  smartSpeed: 450,
  loop: true,
  nav: false,
  dots: false,
  responsive: {
    0: {
      items: 1,
      margin: 30,
      stagePadding: 10,
      smartSpeed: 250,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const storyCarouselData = [
  {
    img: "story-1.jpeg",
    title: "SJF Mission - Better Eyes for a Better Life",
    description: "SJF mission toward society is to prevent them from the health issue, in order with this; SJF organized the Eye healthcare camp in Jharkhand, The program provides them with free eye checkups, spectacles, and eye surgery. Equipped with the most modern facilities & knowledgeable dedicated specialists and highly motivated staff, the SJF is committed to clinical excellence, personalized patient care, and the best eye care services at all times to all our patients.",
  },
  {
    img: "story-3.jpeg",
    title: "Protecting Underprivileged Communities During COVID-19",
    description: "Sharda Jeet Foundation (SJF) got established in the year 2021. It is a Social Service Organization working for the welfare and empowerment of Underprivileged Community. The mission of this foundation is to provide with the necessary resources like books, pencil, notebooks, scholarships to the selected students, etc.",
  },
  {
    img: "story-2.jpeg",
    title: "SJF Mission - Better Eyes for a Better Life",
    description: "SJF mission toward society is to prevent them from the health issue, in order with this; SJF organized the Eye healthcare camp in Jharkhand, The program provides them with free eye checkups, spectacles, and eye surgery. Equipped with the most modern facilities & knowledgeable dedicated specialists and highly motivated staff, the SJF is committed to clinical excellence, personalized patient care, and the best eye care services at all times to all our patients.",
  },
  {
    img: "story-4.jpeg",
    title: "Sharda Jeet Foundation Distributes Masks & Sanitizers in Rural Areas",
    description: "Shardajeet Foundation Organized the Mask & Sanitizer distribution camp in Burhkhaira, Jharkhand during Margh Mela and 5,000 masks were distributed. Wearing a mask is one of the simplest ways to reduce spread of COVID-19, and persuading people and communities to embrace mask use is a core intervention for curbing the pandemic.",
  },
];

const Home = () => {
  return (
    <>
      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1>Helping hand for the underprivileged community</h1>
              <h2>Sharda Jeet Foundation</h2>
              <div>
                <Link to={WebRoutes.DONATE} className="btn-get-started scrollto">
                  Donate Now <i className="icofont-heart-alt"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 hero-img">
              <img src={require("../../static/img/hero-img.svg").default} className="img-fluid animated" alt="hero-img" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      {/* <!-- ======= About Section ======= --> */}
      <section id="about" className="about">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-12 pt-sm-0 pt-5 pt-lg-0">
              <h3 data-aos="fade-up">About Us</h3>
              <p data-aos="fade-up" data-aos-delay="100" className="text-justify">
                Sharda Jeet Foundation (SJF) got established in the year 2021. It is a Social Service Organization working for the welfare and empowerment of Underprivileged Community. The mission of this foundation is to provide with the necessary resources like books, pencil, notebooks, scholarships to the selected students, etc. to the students which are required for the completion of their studies and in the health segment we are mainly focused on the eyesight segment as one can do some amount of work even if he/ she is physically challenged, but being blind or being a person with week eyesight makes it quite a difficult task. So for that we provide people with weak eyesight the necessary equipment’s like specs, organizing timely eyesight checkup camps and also refer people to the
                appropriate doctors.
              </p>

              <div className="row">
                <div className="col-md-4" data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">
                  <Link className="sector-hlght" to={WebRoutes.HEALTH}>
                    <span>
                      <img src="https://kyc.dialmytrip.com/ShardaJeetFoundation/img/programs/Health-1.png" className="img-fluid" alt="Health" />
                    </span>
                    <h4>Health</h4>
                    <p>We aspire to help the underprivileged people with less, inappropriate or no vision by organizing time to time camps and providing basic medical facilities.</p>
                  </Link>
                </div>

                <div className="col-md-4" data-aos="fade-up" data-aos-delay="400" data-aos-duration="600">
                  <Link className="sector-hlght" to={WebRoutes.PROGRAM_EDUCATION}>
                    <span>
                      <img src="https://kyc.dialmytrip.com/ShardaJeetFoundation/img/programs/Education-1.png" className="img-fluid" alt="Education" />
                    </span>
                    <h4>Education</h4>
                    <p>In this program our main motive is to complete basic educational requirements of the underprivileged students/ children for their better education and brighter future. </p>
                  </Link>
                </div>

                <div className="col-md-4" data-aos="fade-up" data-aos-delay="600" data-aos-duration="600">
                  <Link className="sector-hlght" to={WebRoutes.PROGRAM_ENVIRONMENT}>
                    <span>
                      <img src="https://kyc.dialmytrip.com/ShardaJeetFoundation/img/programs/Environment-1.png" className="img-fluid" alt="Environment" />
                    </span>
                    <h4>Environment</h4>
                    <p>We are focused on the most important part of the environment i.e. the plants and hence spread awareness and provide samplings for the tree plantation.</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Section --> */}

      {/* <!-- ======= Contribution Section ======= --> */}
      <section className="contribution section-bg">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-md-5 cont-left">
              <h3>There are many ways to get involved and support us for the betterment of our country.</h3>
              <p>Whether you are an individual, group, or institution you may contribute in any or whatever amount you want and can even volunteer with us. By doing so we can together contribute to the betterment of the underprivileged and this country.</p>
            </div>

            <div className="col-md-5 offset-lg-2">
              {contributionCarouselData && (
                <OwlCarousel className="owl-theme contribution-carousel" {...contributionCarousel}>
                  {contributionCarouselData.map((item, index) => {
                    return (
                      <div className="item" key={index}>
                        <div className="icon-box">
                          <h4 className="title">{item.title}</h4>
                          <p className="description">{item.description}</p>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}

      {/* <!-- ======= 80G Section ======= --> */}
      <section id="donation80G" className="donation80G-Wrp">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-md-4">
              <div className="donation80G" data-aos="flip-up" data-aos-delay="100">
                <img className="img-fluid" src="https://kyc.dialmytrip.com/ShardaJeetFoundation/img/programs/Donation.png" alt="donation80G" />
                <span>All our efforts are made possible only because of your support</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="donation80G" data-aos="flip-up" data-aos-delay="250">
                <img className="img-fluid" src="https://kyc.dialmytrip.com/ShardaJeetFoundation/img/programs/80G.png" alt="donation80G" />
                <span>Your donations are tax exempted under 80G of the Indian Income Tax Act</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="donation80G" data-aos="flip-up" data-aos-delay="400">
                <img className="img-fluid" src="https://kyc.dialmytrip.com/ShardaJeetFoundation/img/programs/Secure.png" alt="donation80G" />
                <span>Your donation transactions are completely safe and secure</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End 80G Section --> */}

      {/* <!-- ======= Stories Section ======= --> */}
      <section className="stories">
        <div className="container-fluid p-0" data-aos="fade-up">
          <div className="row">
            <div className="col-md-12">
              {storyCarouselData && (
                <OwlCarousel className="owl-theme story-carousel" {...storyCarousel}>
                  {storyCarouselData.map((item, index) => {
                    return (
                      <div className="item" key={index}>
                        <div className="storyline">
                          <div className="row g-0">
                            <div className="col-md-6">
                              <div className="story-img">
                                <img className="img-fluid" src={WebRoutes.IMG_BASE_URL + "story/" + item.img} alt="SJF-Story" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="storycont">
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}
    </>
  );
};

export default Home;
