import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "./../../../routes";

const DigitalEyeStrain = () => {
  return (
    <>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Awareness</h2>
            <ol>
              <li>
                <Link to={WebRoutes.HOME}>Home</Link>
              </li>
              <li>
                <Link to={WebRoutes.AWARENESS}>Awareness</Link>
              </li>
              <li>Digital Eye Strain</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs Section --> */}

      {/* <!-- ======= Awareness Banner Section ======= --> */}
      <div className="awarenessBanner" style={{ backgroundImage: `url(${WebRoutes.IMG_BASE_URL + "blog/EyeStrain.png"})` }}>
        <div className="row">
          <div className="col-12">
            <h2>Digital Eye Strain</h2>
          </div>
        </div>
      </div>
      {/* <!-- End Awareness Banner Section --> */}

      <section>
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-8">
              <p>It is an eye and vision problem caused due to prolonged use of digital devices. A person affected due to Digital Eye Strain experiences discomfort in eyes like dry eyes, blurry vision, headache, etc. It becomes a major problem with those people who do not pay attention to these symptoms and continue working as usual without taking any precautions or medications and sometimes the hidden health problems also become a reason for it.</p>
              <b>Prevention Procedures</b>
              <p>The problem of Digital Eye Strain can be prevented if taken care of it at the right time. Following are the methods by which it can be prevented:</p>
              <ul className="awarenessList">
                <li>Using blue-light-blocking glasses protects the eye retina from damage due to elongated exposure to blue light.</li>
                <li>Always maintain a gap of at least 20 inches between yourself and your digital device.</li>
                <li>Take breaks in between while working or using a digital device for a long time.</li>
                <li>Choose the appropriate brightness level and sit in a well-lit place.</li>
                <li>Try to limit or reduce your screen time.</li>
                <li>Keep taking timely eye check-ups at regular intervals.</li>
              </ul>
              <p>Eyes are very much important to perform or do any kind of work. So, it becomes very much important and necessary to take care of your eyes.</p>
            </div>
            <div className="col-4">
              <img src={WebRoutes.IMG_BASE_URL + "blog/EyeStrain.png"} className="img-fluid img-thumbnail" alt="WaterHarvesting" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DigitalEyeStrain;
