import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "../../../routes";

const Environment = () => {
  return (
    <>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Programs</h2>
            <ol>
              <li>
                <Link to={WebRoutes.HOME}>Home</Link>
              </li>
              <li>Programs - Environment</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs Section --> */}

      {/* <!-- ======= Environment Program ======= --> */}
      <section id="Environment" className="Environment">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-12 pt-5 pt-lg-0">
              <h3 data-aos="fade-up" className="mb-4 SJF">
                <b>Environment Program</b>
              </h3>
              <img data-aos="fade-left" data-aos-delay="100" src="https://kyc.dialmytrip.com/ShardaJeetFoundation/img/programs/environment.png" className="img-fluid float-end" alt="program" />
              <p data-aos="fade-up" data-aos-delay="100" className="text-justify">
                The most important part of the environment is the trees. We are dependent on the trees in a lot many ways and we all know the importance of plants and trees in our life as they provide us with the life giving gas i.e. oxygen, along with that we take complete use of almost all the parts of a tree. A thing which is of so much importance for the survival of life on earth should be taken care of.
              </p>
              <p data-aos="fade-up" data-aos-delay="200" className="text-justify">
                Today, the temperature is increasing at such a high pace as we are cutting down trees simultaneously at a large scale, ignoring the consequences which may and are occurring due to it. But, now it’s high time if we will not give attention to it now then our survival on this planet is going to be really difficult.
              </p>
              <p data-aos="fade-up" data-aos-delay="300" className="text-justify">
                Sharda Jeet Foundation knows the importance of the change required in this alarming time. It works on educating people about the importance of trees and spreads awareness about tree plantation and provides with plant samplings for the same.
              </p>
              <p data-aos="fade-up" data-aos-delay="400" className="text-justify">
                If you would like to do something for the environment then plant a tree today or just contribute or donate us some amount to do the same.
              </p>
              <p data-aos="fade-up" data-aos-delay="500" className="text-justify">
                Help us in purifying the air we breathe and reducing the carbon footprint in the environment.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Environment Section --> */}
    </>
  );
};

export default Environment;
