import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "../../../routes";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const activitiesCarousel = {
  stagePadding: 0,
  loop: false,
  nav: false,
  dots: false,
  margin: 20,
  autoplay: true,
  autoplayTimeout: 1000,
  autoplayHoverPause: true,
  autoplaySpeed: 500,
  rewind: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};

const _08Mar2022 = ["img-1.jpeg", "img-2.jpeg", "img-3.jpeg", "img-4.jpeg", "img-5.jpeg", "img-6.jpeg", "img-7.jpeg", "img-8.jpeg", "img-9.jpeg", "img-10.jpeg"];
const _1418Jan2022 = ["img-1.jpeg", "img-2.jpeg", "img-3.jpeg", "img-4.jpeg", "img-5.jpeg", "img-6.jpeg", "img-7.jpeg", "img-8.jpeg", "img-9.jpeg", "img-10.jpeg", "img-11.jpeg", "img-12.jpeg"];
const _09Dec2021 = ["img-1.jpeg", "img-2.jpeg", "img-3.jpeg", "img-4.jpeg", "img-5.jpeg", "img-6.jpeg", "img-7.jpeg", "img-8.jpeg", "img-9.jpeg", "img-10.jpeg", "img-11.jpeg", "img-12.jpeg"];

const Health = () => {
  return (
    <>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Programs</h2>
            <ol>
              <li>
                <Link to={WebRoutes.HOME}>Home</Link>
              </li>
              <li>Programs - Health</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs Section --> */}

      {/* <!-- ======= Health Program ======= --> */}
      <section id="Environment" className="Environment">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-12 pt-5 pt-lg-0">
              <h3 data-aos="fade-up" className="mb-4 SJF">
                <b>Health Program</b>
              </h3>
              <img data-aos="fade-left" data-aos-delay="100" src="https://kyc.dialmytrip.com/ShardaJeetFoundation/img/programs/health.png" className="img-fluid float-end" alt="program" />
              <p data-aos="fade-up" data-aos-delay="100" className="text-justify">
                Even today a lot many children are unable to get access to even the basic education requirements. Government has opened up schools for these children but it lags in providing and completing all the requirements which are quite necessary for a child to study. These things include study materials like books, notebooks, pens, pencils, bags, etc.
              </p>
              <p data-aos="fade-up" data-aos-delay="200" className="text-justify">
                Sharda Jeet Foundation focuses on such problems and tries to fill this gap between the children and their basic necessities for studies.
              </p>
              <p data-aos="fade-up" data-aos-delay="300" className="text-justify">
                We obviously on our own and solely can’t achieve all of it. So, that is why we are open to all types of donations and contributions by any firm, individual or an organization. As SJF knows that we all can together contribute for the better future and better education of the underprivileged students.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Health Section --> */}

      {/* <!-- ======= Health Program ======= --> */}
      <section id="Activities" className="Activities pt-4">
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Our Activities</h2>
            <p>Our Contribution towards Health</p>
          </div>

          <div className="row">
            <div className="col-md-12" data-aos="fade-up">
              <div className="activityDescribe">
                <h3>Eye Care Camp Organized by Sharda Jeet Foundation</h3>
                <span className="dte">08, March 2022</span>
              </div>
              <p className="captn">Eye Examination and Treatment camp was organized by Sharda Jeet Foundation in village Dalakalan, District Palamu, Jharkhand, on this occasion more than 400 people were examined and 160 people got glasses and 200 people got eye drops. Drops and medicines were given! Dr. Prakash Parimal and his team told that 55 people are to be operated for cataract, the cost of which will be borne by Sharda Jeet Foundation.</p>
              {_08Mar2022 && (
                <OwlCarousel className="activities-carousel owl-theme" {...activitiesCarousel}>
                  {_08Mar2022.map((item, index) => {
                    return (
                      <div className="item" key={index}>
                        <a href={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/" + item} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                          <img src={WebRoutes.IMG_BASE_URL + "programs/Health/08Mar2022/" + item} className="img-fluid rounded" alt="SJF-Activities" />
                        </a>
                      </div>
                    );
                  })}
                </OwlCarousel>
              )}
            </div>

            <div className="col-md-12" data-aos="fade-up">
              <div className="activityDescribe">
                <h3>Sharda Jeet Foundation Distributes Masks & Sanitizers in Rural Areas</h3>
                <span className="dte">14-18, January 2022</span>
              </div>
              <p className="captn">Shardajeet Foundation Organized the Mask & Sanitizer distribution camp in Burhkhaira, Jharkhand during Margh Mela and 5,000 masks were distributed. Wearing a mask is one of the simplest ways to reduce spread of COVID-19, and persuading people and communities to embrace mask use is a core intervention for curbing the pandemic.</p>
              {_1418Jan2022 && (
                <OwlCarousel className="activities-carousel owl-theme" {...activitiesCarousel}>
                  {_1418Jan2022.map((item, index) => {
                    return (
                      <div className="item" key={index}>
                        <a href={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/" + item} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                          <img src={WebRoutes.IMG_BASE_URL + "programs/Health/1418Jan2022/" + item} className="img-fluid rounded" alt="SJF-Activities" />
                        </a>
                      </div>
                    );
                  })}
                </OwlCarousel>
              )}
            </div>

            <div className="col-md-12" data-aos="fade-up">
              <div className="activityDescribe">
                <h3>Eye Care Camp Organized by Sharda Jeet Foundation</h3>
                <span className="dte">09, December 2021</span>
              </div>
              <p className="captn">Shardajeet Foundation Organized the Eyecare Health Camp in Dala Kalan Village Jharkhand, More than 400 Patients were treated in this camp & 140 patients got received the medicine and Spectacles. Our mission is to envision where people are enabled by good eyesight to attain their full potential.</p>
              {_09Dec2021 && (
                <OwlCarousel className="activities-carousel owl-theme" {...activitiesCarousel}>
                  {_09Dec2021.map((item, index) => {
                    return (
                      <div className="item" key={index}>
                        <a href={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/" + item} data-fancybox="SJFGallery" className="gallery-lightbox gallery-wrap">
                          <img src={WebRoutes.IMG_BASE_URL + "programs/Health/09Dec2021/" + item} className="img-fluid rounded" alt="SJF-Activities" />
                        </a>
                      </div>
                    );
                  })}
                </OwlCarousel>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Health Section --> */}
    </>
  );
};

export default Health;
