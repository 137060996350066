import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "./../../routes";

const RefundPolicy = () => {
  return (
    <>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Refund Policy</h2>
            <ol>
              <li>
                <Link to={WebRoutes.HOME}>Home</Link>
              </li>
              <li>Refund Policy</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs Section --> */}

      {/* <!-- ======= Refund Policy ======= --> */}
      <section>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-12 pt-5 pt-lg-0">
              <h3 data-aos="fade-up" className="mb-4 SJF">
                <b>Refund Policy</b>
              </h3>
              <img data-aos="fade-left" data-aos-delay="100" src={require("../../static/img/Refund.png")} className="img-fluid float-end" alt="Policy" />
              <p data-aos="fade-up" data-aos-delay="100" className="text-justify">
                Cancellation of donation can be requested anytime by contacting at{" "}
                <a href="mailto:info@shardajeetfoundation.org">
                  <b>info@shardajeetfoundation.org</b>
                </a>
                . Cancellation of already debited amount will be processed as per refund policy.
              </p>
              <p data-aos="fade-up" data-aos-delay="200" className="text-justify">
                We follow a reliable refund policy to let our donors feel privileged about their association with us. We take utmost care about processing donations as per the mandates signed by our donors in the donor forms, both offline and online. But in case of an unlikely event of an erroneous deduction, <b>Sharda Jeet Foundation</b> will refund the wrongly deduced amount within 7-10 working days from the date of receiving the complaint from donor. The donor must report within 72hrs of the deduction. We would require a proof of deduction of the donation amount and a written request for refund from the donor. If the request is made post 72hrs, the refund can only be processed if the donated amount is not already been utilized.
              </p>
              <p data-aos="fade-up" data-aos-delay="300" className="text-justify">
                We can be contacted for refund request through the following:
              </p>
              <p data-aos="fade-up" data-aos-delay="400" className="text-justify">
                Write to us on{" "}
                <a href="mailto:info@shardajeetfoundation.org">
                  <b>info@shardajeetfoundation.org</b>
                </a>
              </p>
              <p data-aos="fade-up" data-aos-delay="500" className="text-justify">
                Call us at{" "}
                <a href="tel:+917011796616">
                  <b>+91-7011796616</b>
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Refund Policy Section --> */}
    </>
  );
};

export default RefundPolicy;
