import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "../../routes";

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="footer-newsletter">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <h4>Join Our Awareness Drive</h4>
                <span>Stay updated on all the work we do</span>
                <div className="mailr">
                  <input type="email" name="email" />
                  <input type="submit" value="Subscribe" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <img src={require("../../static/img/logo-W.png")} alt="logo" className="img-fluid w-75 mb-4" />
                <p>
                  <strong>Phone:</strong> +91 7011796616
                  <br />
                  <strong>Email:</strong> info@shardajeetfoundation.org
                  <br />
                </p>
              </div>

              <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6 footer-links">
                <h4>Organization</h4>
                <ul>
                  <li>
                    <i className="icofont-rounded-right"></i>
                    <Link to={WebRoutes.HOME}>Home</Link>
                  </li>
                  <li>
                    <i className="icofont-rounded-right"></i>
                    <Link to={WebRoutes.ABOUT_US}>About us</Link>
                  </li>
                  <li>
                    <i className="icofont-rounded-right"></i>
                    <Link to={WebRoutes.VISION_MISSION}>What we do?</Link>
                  </li>
                  <li>
                    <i className="icofont-rounded-right"></i>
                    <Link to={WebRoutes.VISION_MISSION}>Vision & Mission</Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6 footer-links">
                <h4>Programs</h4>
                <ul>
                  <li>
                    <i className="icofont-rounded-right"></i>
                    <Link to={WebRoutes.PROGRAM_HEALTH}>Health</Link>
                  </li>
                  <li>
                    <i className="icofont-rounded-right"></i>
                    <Link to={WebRoutes.PROGRAM_EDUCATION}>Education</Link>
                  </li>
                  <li>
                    <i className="icofont-rounded-right"></i>
                    <Link to={WebRoutes.PROGRAM_ENVIRONMENT}>Environment</Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>More Links</h4>
                <ul>
                  <li>
                    <i className="icofont-rounded-right"></i>
                    <Link to={WebRoutes.GALLERY}>Gallery</Link>
                  </li>
                  <li>
                    <i className="icofont-rounded-right"></i>
                    <Link to={WebRoutes.CONTACT_US}>Contact Us</Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Social Networks</h4>
                <p>You can follow us on the following social media handles and can keep a track on what all activities and work we do.</p>
                <div className="social-links mt-3">
                  <a href="https://www.facebook.com/Shardajeetfoundation-104177782048585" className="facebook" target="_blank" rel="noreferrer">
                    <i className="icofont-facebook"></i>
                  </a>
                  <a href="https://www.instagram.com/shardajeetfoundation" className="instagram" target="_blank" rel="noreferrer">
                    <i className="icofont-instagram"></i>
                  </a>
                  <a href="https://twitter.com/shardajeet" className="twitter" target="_blank" rel="noreferrer">
                    <i className="icofont-twitter"></i>
                  </a>
                  <a href="https://www.youtube.com/channel/UCYUAsooG14WpC1AzOWL3b8Q" className="google-plus" target="_blank" rel="noreferrer">
                    <i className="icofont-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ background: "var(--SJF-yellow)" }}>
          <div className="container py-3">
            <div className="copyright">
              &copy; Copyright{" "}
              <strong>
                <span>Sharda Jeet Foundation</span>
              </strong>
              . All Rights Reserved
              <span className="float-end">
                <Link to={WebRoutes.PRIVACY_POLICY}>Privacy Policy</Link> — <Link to={WebRoutes.REFUND_POLICY}>Refund Policy</Link> — <Link to={WebRoutes.TERMS_CONDITIONS}>Terms & Conditions</Link>
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
