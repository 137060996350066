import React, {useState } from 'react';
import { Link } from "react-router-dom";
// import emailjs from '@emailjs/browser';
import { WebRoutes } from "../../routes";

const Contact = () => {
  
  const[fromState,setFormState]=useState({});
  const changeHandler=(e)=>{
    setFormState({...fromState,[e.target.name]:e.target.value})
  }
  const SubmitHandler=(event)=>{
    event.preventDefault();  
    // const config={
    //   // SecureToken:"d6813a0f-ccf4-4f27-a813-209e2c0ee341",
    //   Host : "smtp.elasticemail.com",
    //   Username : "satishabhi2301@gmail.com",
    //   Password : "26E3F402E26BE8AE6B50F2A8AC8978902C2D",
    //   To : "satishabhi2301@gmail.com",
    //   From : fromState.user_email,
    //   Subject :fromState.user_subject ,
    //   Body : fromState.user_message
    // }
    window.Email.send({
      Host : "smtp.elasticemail.com",
      Username : "sc43502@gmail.com",
      Password : "0382F093007783A7A937DA178AF76C48EAEA",
      To : "webdevelopersatish@gmail.com",
      From :"sc43502@gmail.com",
      ReplyFrom : document.getElementById("email").value,// user 
      Subject :fromState.user_subject,
      Body : fromState.user_name+" "+fromState.user_message
  }).then(
    message => alert("message sent successfully")
  ); 
 
    document.getElementById("name").value="";
    document.getElementById("email").value="";
    document.getElementById("subject").value="";
    document.getElementById("message").value="";
  }
  
  return (
    <>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Contact Us</h2>
            <ol>
              <li>
                <Link to={WebRoutes.HOME}>Home</Link>
              </li>
              <li>Contact Us</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs Section --> */}

      {/* <!-- ======= Contact Us Section ======= --> */}
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Contact Us</h2>
            <p>If you have any queries</p>
          </div>

          <div className="row mb-5">
            <div className="col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
              <div className="info">
                <h5>Registered Address</h5>
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>
                    516, Vishal Chamber, Sector-18,
                    <br />
                    Noida-201301, Uttar Pradesh
                  </p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <a href="mailto:info@shardajeetfoundation.org">info@shardajeetfoundation.org</a>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <a className="mb-0" href="tel:7011796616">
                    +91 7011796616
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
              <img src={require("../../static/img/logo-4X4.png")} className="img-fluid w-75 m-auto" alt="SJF" />
            </div>
            <div className="col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
              <div className="info">
                <h5>Branch Address</h5>
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>
                    Village Post: Dalakala,
                    <br />
                    District: Palamu, Jharkhand-822124
                  </p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <a href="mailto:info@shardajeetfoundation.org">info@shardajeetfoundation.org</a>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <a className="mb-0" href="tel:8877617261">
                    +91 8877617261
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
              <div className="info">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.0213106180377!2d77.32311941503482!3d28.569122782442832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce521926ed7fd%3A0x7ed73f3f40340355!2sDialmytrip%20Tech%20Private%20Limited!5e0!3m2!1sen!2sin!4v1629542060291!5m2!1sen!2sin" title="SJF-Location" frameborder="0" allowfullscreen></iframe>
              </div>
            </div>

            <div className="col-lg-6 mt-5 mt-lg-0 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
              <div className="contactUs-form">
              <form  onSubmit={SubmitHandler}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Your Name</label>
                    <input id="name" type="text" className="form-control text-capitalize" placeholder="Your Name" name="user_name" value={fromState.user_name} onChange={changeHandler} />
                  </div>
                  <div className="form-group col-md-6 mt-3 mt-md-0">
                    <label>Your Email</label>
                    <input id="email" type="email" className="form-control" placeholder="Your Email" name="user_email" value={fromState.user_email} onChange={changeHandler} />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label>Subject</label>
                  <input id="subject" type="text" className="form-control" placeholder="Subject"  name="user_subject" value={fromState.user_subject} onChange={changeHandler}/>
                </div>
                <div className="form-group mt-3">
                  <label>Message</label>
                  <textarea id="message" className="form-control" placeholder="Write message here..." rows={4} name="user_message" value={fromState.user_message} onChange={changeHandler}></textarea>
                </div>
                <div className="text-center">
                  <button className="donate-btn" value="Send">Send Message</button>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Contact Us Section --> */}
    </>
  );
};

export default Contact;
