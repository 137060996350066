import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "./../../routes";

const PrivacyPolicy = () => {
  return (
    <>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Privacy Policy</h2>
            <ol>
              <li>
                <Link to={WebRoutes.HOME}>Home</Link>
              </li>
              <li>Privacy Policy</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs Section --> */}

      {/* <!-- ======= Privacy Policy ======= --> */}
      <section>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-12 pt-5 pt-lg-0">
              <h3 data-aos="fade-up" className="mb-4 SJF">
                <b>Privacy Policy</b>
              </h3>
              <img data-aos="fade-left" data-aos-delay="100" src={require("../../static/img/Privacy.png")} className="img-fluid float-end" alt="Policy" />
              <p data-aos="fade-up" data-aos-delay="100" className="text-justify">
                Sharda Jeet Foundation is totally committed to protecting your personal information.
              </p>
              <p data-aos="fade-up" data-aos-delay="200" className="text-justify">
                Please read this privacy policy carefully as it describes how we handle your personal information.
              </p>
              <p data-aos="fade-up" data-aos-delay="300" className="text-justify">
                We may revise this privacy policy from time to time by publishing a revised version on our website. That revised version takes effect from the time it is published.
              </p>

              <h5 data-aos="fade-up" data-aos-delay="400">
                <b>Collection of Personal Information</b>
              </h5>
              <p data-aos="fade-up" data-aos-delay="400" className="text-justify">
                ‘Personal information’ simply put is any information or opinion that can identify or be used to identify you. We collect and hold personal information from our donors, supporters, volunteers, contractors, visitors to our field programs, and other individuals.
              </p>

              <h5 data-aos="fade-up" data-aos-delay="500">
                <b>Cookies</b>
              </h5>
              <p data-aos="fade-up" data-aos-delay="500" className="text-justify">
                When you use our website, we may collect personal information from you automatically through cookies including: your IP address and or domain name; your operating system (type of browser and platform); and the date, time and length of your visit to the website. We use this information primarily for the compilation of statistical information about the use of our website.
              </p>
              <p data-aos="fade-up" data-aos-delay="500" className="text-justify">
                Most web browsers are set by default to accept cookies. However, if you do not wish to receive any cookies, you may set your browser to either prompt you to refuse cookies or automatically refuse cookies.
              </p>

              <h5 data-aos="fade-up" data-aos-delay="600">
                <b>Links to Other Websites</b>
              </h5>
              <p data-aos="fade-up" data-aos-delay="600" className="text-justify">
                The Sharda Jeet Foundation website may contain links to third party websites, and third party websites may also have links to the Sharda Jeet Foundation. Our privacy policy does not apply to external links or other websites. These third party websites may collect your personal information. We encourage you to read the privacy policies of any website you link to from our website.
              </p>

              <h5 data-aos="fade-up" data-aos-delay="700">
                <b>Use of Personal Information</b>
              </h5>
              <p data-aos="fade-up" data-aos-delay="700" className="text-justify">
                <span>We use your personal information primarily to:</span>
                <ul style={{ listStyleType: "lower-latin", paddingLeft: "16px" }} data-aos="fade-up" data-aos-delay="700">
                  <li>Process donations;</li>
                  <li>Communicate with our supporters;</li>
                  <li>Recruit and manage staff, contractors or volunteers;</li>
                  <li>Conduct marketing activities; and</li>
                  <li>Enable other activities and functions of SJF.</li>
                </ul>
              </p>
              <p data-aos="fade-up" data-aos-delay="700" className="text-justify">
                If you decide not to provide personal information, we may not be able to do these activities and functions.
              </p>
              <p data-aos="fade-up" data-aos-delay="700" className="text-justify">
                We do not sell your personal information to third parties, and except as provided for in this privacy policy we do not make your personal information available to third parties.
              </p>

              <h5 data-aos="fade-up" data-aos-delay="800">
                <b>Security of Personal Information</b>
              </h5>
              <p data-aos="fade-up" data-aos-delay="800" className="text-justify">
                However, all unencrypted information exchanged via the internet may be accessed and used by people other than those for whom it is intended, for example if you send us any personal information by email it is sent at your own risk.
              </p>

              <h5 data-aos="fade-up" data-aos-delay="900">
                <b>Online Donations</b>
              </h5>
              <p data-aos="fade-up" data-aos-delay="900" className="text-justify">
                Donations made online on this website are processed in real time using a secure payment gateway. Donations are processed in India in INR. If you have any questions or concerns about making an online donation to Sharda Jeet Foundation , Please contact our Supporter Services team at Sharda Jeet Foundation - 516, Vishal Chambers Sector-18 Noida, Uttar Pradesh-201301, India or{" "}
                <a href="mailto:info@shardajeetfoundation.org">
                  <b>info@shardajeetfoundation.org</b>
                </a>{" "}
                or{" "}
                <a href="tel:+917011796616">
                  <b>+91-7011796616</b>
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Privacy Policy Section --> */}
    </>
  );
};

export default PrivacyPolicy;
