import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./common/header";
import Footer from "./common/footer";
import AOS from "aos";
import "aos/dist/aos.css";

const BaseComponent = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
       <Header />
      <main id="main">
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default BaseComponent;
